import { Typography, Layout, List, Flex, theme } from "antd";
import { Footer, Header } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";

const { Title, Paragraph, Text, Link } = Typography;
const { Content } = Layout;

function PrivacyPolicy() {
  const navigate = useNavigate();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout style={{ background: colorBgContainer }}>
      <Header
        style={{
          background: colorBgContainer,
          position: "sticky",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          top: 0,
          zIndex: 999,
          width: "100%",
          boxShadow: "rgba(0, 0, 0, 0.1) 20px 0px 26px -7px",
        }}>
        <img
          style={{
            cursor: "pointer",
          }}
          src="/logo.svg"
          alt="Yozo.ai ai generator"
          width={130}
          onClick={() => navigate("/")}
        />
        <Flex
          justify="start"
          align="center">
          <a href="mailto:contact@yozo.ai">contact@yozo.ai</a>
        </Flex>
      </Header>

      <Content
        style={{
          padding: "20px",
          maxWidth: "1200px",
          margin: "0 auto",
        }}>
        <Typography>
          <Title level={1}>Privacy Policy</Title>
          <Paragraph>
            At Yozo.ai, we respect your privacy and are committed to protecting
            your personal data. This privacy policy explains how we collect,
            use, and share information about you when you use our services,
            including the Yozo.ai SEO Blogs Generator plugin.
          </Paragraph>

          <Title level={2}>Information We Collect</Title>
          <Paragraph>
            <Text strong>Personal Data:</Text> When you register for an account,
            use our services, or contact us, we may collect personal data such
            as your name, email address, and payment information.
          </Paragraph>
          <Paragraph>
            <Text strong>Content Data:</Text> To provide our services, we may
            collect and process data related to the content you create using our
            plugin, including product descriptions, meta tags, and blog posts.
          </Paragraph>

          <Title level={2}>How We Use Your Information</Title>
          <Paragraph>We use the information we collect to:</Paragraph>
          <List
            dataSource={[
              "Provide, operate, and maintain our services",
              "Improve, personalize, and expand our services",
              "Communicate with you, including customer support",
              "Process transactions and manage subscriptions",
              "Understand and analyze how you use our services",
            ]}
            renderItem={(item) => <List.Item>{item}</List.Item>}
            style={{ paddingLeft: "20px" }}
          />

          <Title level={2}>Sharing Your Information</Title>
          <Paragraph>
            We do not sell your personal information to third parties. We may
            share your information with:
          </Paragraph>
          <List
            dataSource={[
              <span key="service-providers">
                <Text strong>Service Providers:</Text> To perform services on
                our behalf, such as payment processing and data analysis.
              </span>,
              <span key="compliance">
                <Text strong>Compliance with Laws:</Text> To comply with legal
                obligations or protect our rights and interests.
              </span>,
            ]}
            renderItem={(item) => <List.Item>{item}</List.Item>}
            style={{ paddingLeft: "20px" }}
          />

          <Title level={2}>Your Rights</Title>
          <Paragraph>
            You have the right to access, update, or delete your personal data.
            If you wish to exercise these rights, please contact us at{" "}
            <Link href="mailto:contact@yozo.ai">contact@yozo.ai</Link>.
          </Paragraph>

          <Title level={2}>Changes to This Privacy Policy</Title>
          <Paragraph>
            We may update this privacy policy from time to time. We will notify
            you of any changes by posting the new privacy policy on our website.
          </Paragraph>

          <Title level={2}>Contact Us</Title>
          <Paragraph>
            If you have any questions about this privacy policy, please contact
            us at <Link href="mailto:contact@yozo.ai">contact@yozo.ai</Link>.
          </Paragraph>
        </Typography>
      </Content>
      <Footer
        style={{
          background: colorBgContainer,
          // position: "sticky",
          top: 0,
          zIndex: 999,
          width: "100%",
          boxShadow: "rgba(0, 0, 0, 0.1) 20px 0px 26px -7px",
        }}>
        <Title
          level={1}
          style={{
            color: "black",
            textAlign: "center",
            font: "Plus Jakarta Sans",
            fontSize: "16px",
            fontWeight: "800",
          }}>
          Copyright © 2024 Yozo.ai All Rights Reserved.
        </Title>
        <Flex
          align="center"
          justify="center">
          <Link to={"/privacy-policy"}>Privacy Policy</Link>
        </Flex>
      </Footer>
    </Layout>
  );
}

export default PrivacyPolicy;

import {
  Image,
  Flex,
  Layout,
  theme,
  Typography,
  Row,
  Col,
  Steps,
  Button,
} from "antd";
import { useState } from "react";
import AppsView from "./AppsView";
import { Link, useNavigate } from "react-router-dom";

export default function App() {
  const navigate = useNavigate();
  const { Header, Content, Footer } = Layout;
  const { Title } = Typography;
  const [current, setCurrent] = useState(0);
  const [previewImage, setPreviewImage] = useState(
    "https://i.postimg.cc/y88m1rvh/Screenshot-from-2024-05-06-02-52-44.png"
  );
  const onChange = (value) => {
    // eslint-disable-next-line default-case
    switch (value) {
      case 0: {
        setPreviewImage(
          "https://i.postimg.cc/y88m1rvh/Screenshot-from-2024-05-06-02-52-44.png"
        );
        break;
      }
      case 1: {
        setPreviewImage(
          "https://i.postimg.cc/P5VmPzRg/Screenshot-from-2024-05-06-02-53-45.png"
        );
        break;
      }
      case 2: {
        setPreviewImage(
          "https://i.postimg.cc/PqL1ZvGP/Screenshot-from-2024-05-06-04-29-10.png"
        );
        break;
      }
    }
    setCurrent(value);
  };
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout>
      <Header
        style={{
          background: colorBgContainer,
          position: "sticky",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          top: 0,
          zIndex: 999,
          width: "100%",
          boxShadow: "rgba(0, 0, 0, 0.1) 20px 0px 26px -7px",
        }}>
        <img
          style={{ cursor: "pointer" }}
          src="/logo.svg"
          alt="Yozo.ai ai generator"
          width={130}
          onClick={() => navigate("/", { replace: true })}
        />
        <Flex
          justify="start"
          align="center">
          <a href="mailto:contact@yozo.ai">contact@yozo.ai</a>
        </Flex>
      </Header>
      <Content>
        <Flex
          vertical={true}
          gap={40}
          style={{
            backgroundColor: "#10A37F",
            padding: "50px 0px",
            height: "70vh",
          }}
          align={"center"}
          justify={"center"}>
          {" "}
          <Title
            level={1}
            style={{
              color: "white",
              textAlign: "center",
              font: "Nunito",
              fontSize: "60px",
              width: "60%",
            }}>
            Speed-Writing Secrets Turbocharge Your Website Copy in Minutes!
          </Title>
          <Title
            level={5}
            style={{
              color: "white",
              textAlign: "center",
              font: "Nunito",
              fontWeight: "400",
              fontSize: "16px",
              margin: "0px",
            }}>
            Discover lightning-fast techniques to craft compelling website copy
            that captivates your audience instantly.
          </Title>
          <Flex
            gap={20}
            wrap={"wrap"}
            justify={"center"}
            style={{ marginBottom: "20px" }}>
            <Button
              onClick={() =>
                (window.location.href = "https://enterprise.yozo.ai/login")
              }
              className={`tryButtonLarge`}>
              Try for free
            </Button>
          </Flex>
        </Flex>
        <AppsView />
        <Flex
          id="features"
          vertical={true}
          style={{ backgroundColor: "#FFFFFF", paddingInline: "50px" }}>
          <Row gutter={[30, 30]}>
            <Col span={24}>
              <Title
                level={2}
                style={{
                  textAlign: "center",
                  font: "Nunito",
                  fontSize: "40px",
                  fontWeight: "600",
                }}>
                How to optimize your products?
              </Title>
            </Col>
            <Col span={24}>
              <Steps
                type={"navigation"}
                current={current}
                onChange={onChange}
                items={[
                  {
                    title: "Choose products",
                  },
                  {
                    title: "Select properties",
                  },
                  {
                    title: "Review and publish",
                  },
                ]}
                responsive={true}
              />
            </Col>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <Image
                src={previewImage}
                alt={"Screenshot-from-2024-05-06-02-46-14"}
                width={"80%"}
                preview={false}
              />
            </Col>
          </Row>
        </Flex>
      </Content>
      <Footer
        style={{
          background: colorBgContainer,
          // position: "sticky",
          top: 0,
          zIndex: 999,
          width: "100%",
          boxShadow: "rgba(0, 0, 0, 0.1) 20px 0px 26px -7px",
        }}>
        <Title
          level={1}
          style={{
            color: "black",
            textAlign: "center",
            font: "Plus Jakarta Sans",
            fontSize: "16px",
            fontWeight: "800",
          }}>
          Copyright © 2024 Yozo.ai All Rights Reserved.
        </Title>
        <Flex
          align="center"
          justify="center">
          <Link to={"/privacy-policy"}>Privacy Policy</Link>
        </Flex>
      </Footer>
    </Layout>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ConfigProvider } from "antd";
import PrivacyPolicy from "./PrivacyPolicy";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ConfigProvider
              theme={{
                token: {
                  //Primary
                  colorPrimary: "#10A37F",
                  //Link
                  colorLink: "#4891FC",
                  fontSize: 16,
                },
                components: {
                  Table: {
                    headerBg: "#10A37F",
                    headerColor: "white",
                    headerSortHoverBg: "#10a37fd4",
                    headerSortActiveBg: "#10a37F",
                    bodySortBg: "#00000010",
                  },
                },
              }}>
              <App />
            </ConfigProvider>
          }
        />
        <Route
          path="/privacy-policy"
          element={<PrivacyPolicy />}
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

import React from "react";
import { Row, Col, Card, Button, Typography } from "antd";
import "./index.css";

const { Meta } = Card;
const { Title } = Typography;

const AppsView = () => {
  const platforms = [
    {
      name: "Shopify",
      logo: "https://polaris.shopify.com/images/shopify-logo.svg",
      apps: [
        {
          name: "Yozo AI SEO Blogs",
          link: "https://apps.shopify.com/scribe-1",
        },
        {
          name: "Yozo AI Product/Collections Descriptions",
          link: "https://apps.shopify.com/scribe-ai-catalogue",
        },
      ],
    },
    {
      name: "Salla",
      logo: "https://cdn.salla.network/images/logo/logo-wide.svg",
      apps: [
        {
          name: "Yozo AI SEO Blogs",
          link: null, // Coming soon
        },
        {
          name: "Yozo AI Product/Collections Descriptions",
          link: null, // Coming soon
        },
      ],
    },
    {
      name: "WordPress",
      logo: "https://s.w.org/style/images/about/WordPress-logotype-standard.png",
      apps: [
        {
          name: "Yozo AI SEO Blogs",
          link: null, // Coming soon
        },
        {
          name: "Yozo AI Product/Collections Descriptions",
          link: null, // Coming soon
        },
      ],
    },
  ];

  return (
    <div style={{ padding: "40px", backgroundColor: "#f9f9f9" }}>
      <Title
        level={2}
        style={{ textAlign: "center", marginBottom: "40px" }}>
        We're available on
      </Title>
      <Row gutter={[24, 24]}>
        {platforms.map((platform) => (
          <Col
            span={8}
            key={platform.name}>
            <Card
              cover={
                <img
                  alt={`${platform.name} logo`}
                  src={platform.logo}
                  className="platform-logo"
                />
              }
              hoverable
              className="platform-card">
              <Meta
                title={
                  <Title
                    level={4}
                    style={{ marginBottom: 0 }}>
                    {platform.name}
                  </Title>
                }
              />
              {platform.apps.map((app) => (
                <div
                  key={app.name}
                  style={{ marginTop: "20px" }}>
                  <Title
                    level={5}
                    style={{ marginBottom: "10px" }}>
                    {app.name}
                  </Title>
                  {app.link ? (
                    <Button
                      type="primary"
                      href={app.link}
                      target="_blank"
                      style={{ width: "100%", borderRadius: "5px" }}>
                      View on {platform.name}
                    </Button>
                  ) : (
                    <Button
                      type="default"
                      disabled
                      style={{ width: "100%", borderRadius: "5px" }}>
                      Coming Soon
                    </Button>
                  )}
                </div>
              ))}
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default AppsView;
